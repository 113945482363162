header {
  padding: 35px 0;
  color: var(--white);
  background: linear-gradient(90deg, var(--blue-light), var(--blue-dark));
  @media (max-width: 1140px) {
    padding: 20px 0; }
  @media (max-width: 768px) {
    padding: 15px 0; }

  .navbar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 26px;
    text-align: center;
    @media (max-width: 1840px) {
      font-size: 20px; }
    @media (max-width: 1500px) {
      justify-content: flex-start; }
    @media (max-width: 1300px) {
      font-size: 18px; }
    @media (max-width: 768px) {
      justify-content: space-between; }

    .burger {
      display: flex;
      align-items: center;
      width: 75px;
      height: 75px;
      cursor: pointer;
      --btn-color: #fff;
      @media (max-width: 1840px) {
        width: 55px;
        height: 55px; }
      @media (max-width: 1500px) {
        margin-right: 35px; }
      @media (max-width: 1300px) {
        width: 45px;
        height: 45px; }
      @media (max-width: 768px) {
        margin-right: 0;
        width: 35px;
        height: 35px; }
      .burger-btn {
        width: 100%;
        height: var(--burger-height);
        border-radius: 5px;
        background-color: var(--btn-color);
        position: relative;
        --burger-distance: -20px;
        --burger-height: 10px;
        @media (max-width: 1840px) {
          --burger-height: 7px;
          --burger-distance: 14px; }
        @media (max-width: 1140px) {
          --burger-height: 5px;
          --burger-distance: 12px; }
        @media (max-width: 768px) {
          --burger-height: 4px;
          --burger-distance: 10px; }

        &::before, &::after {
          @include ba();
          width: 100%;
          height: var(--burger-height);
          border-radius: 5px;
          background-color: var(--btn-color); }
        &::before {
          top: var(--burger-distance); }
        &::after {
          bottom: var(--burger-distance); } } }

    .logo {
      width: 450px;
      @media (max-width: 1840px) {
        width: 350px; }
      @media (max-width: 1500px) {
        width: 250px; }
      @media (max-width: 1140px) {
        width: 180px; }
      @media (max-width: 500px) {
        width: 140px; }
      img {
        width: 100%; } }

    .search {
      width: 700px;
      height: 65px;
      display: flex;
      align-items: center;
      position: relative;
      overflow: hidden;
      @media (max-width: 1840px) {
        width: 500px;
        height: 55px; }
      @media (max-width: 1500px) {
        margin-left: auto;
        width: 350px;
        height: 40px; }
      @media (max-width: 1140px) {
        width: 50px;
        overflow: visible;
        input, #search {
          transition: all .3s ease;
          opacity: 0;
          visibility: hidden; }
        input {
          width: 200px;
          position: absolute;
          top: 50px;
          right: 0;
          z-index: 4;
          color: #333;
          &::placeholder {
            color: #555; } }
        #search {
          top: 60px;
          z-index: 5;
          transition: all .3s ease; }
        &:hover {
          input, #search {
            top: 0;
            opacity: 1;
            visibility: visible; }
          #search {
            top: 9px; } } }
      @media (max-width: 768px) {
        margin: 0; }

      input {
        padding: 0 25px;
        width: 100%;
        height: 120%;
        border: none;
        outline: none;
        border-radius: 40px;
        background: rgba(255,255,255, .7);
        color: #fff;
        font-size: 28px;
        &::placeholder {
          color: #fff; }
        @media (max-width: 1840px) {
          font-size: 22px; }
        @media (max-width: 1500px) {
          font-size: 18px; } }
      button {
        width: 34px;
        height: 34px;
        border: none;
        cursor: pointer;
        background: center / contain no-repeat url('../img/search.png');
        position: absolute;
        top: calc(50% - 17px);
        right: 20px;
        @media (max-width: 1500px) {
          width: 24px;
          height: 24px;
          top: calc(50% - 12px); } } }

    .delivery, .cart {
      display: flex;
      align-items: center;
      flex-direction: column;
      color: var(--white);
      text-decoration: none;
      img {
        margin-bottom: 5px; }
      @media (max-width: 1500px) {
        margin-left: 25px;
        img {
          width: 34px;
          height: 34px;
          object-fit: contain; } }
      @media (max-width: 768px) {
        display: none; } }

    .delivery {}
    .cart {} }

  .sidebar {
    width: 100%;
    height: 100%;
    overflow-y: auto;
    background: rgba(0, 0, 0, .5);
    border-right: 1px solid var(--blue-light);
    transition: all .5s ease;
    position: fixed;
    left: 0;
    top: 0;
    z-index: 10;
    opacity: 0;
    visibility: hidden;
    &.active {
      left: 0;
      opacity: 1;
      visibility: visible;
      ul {
        left: 0; } }
    .btn-close-sb {
      width: 50px;
      height: 50px;
      display: flex;
      align-items: center;
      position: absolute;
      right: 35px;
      top: 25px;
      --close-bg: #fff;
      @media (max-width: 768px) {
        right: 15px;
        top: 15px;
        width: 35px;
        height: 35px; }
      &::before, &::after {
        @include ba();
        width: 100%;
        height: 4px;
        border-radius: 3px;
        background-color: var(--close-bg); }
      &::before {
        transform: rotate(45deg); }
      &::after {
        transform: rotate(-45deg); } }
    & > ul {
      left: -100%;
      transition: all .3s ease;
      position: absolute;
      background-color: var(--white);
      padding: 70px 30px 50px;
      list-style: none;
      width: 500px;
      min-height: 100vh;
      font-size: 22px;
      @media (max-width: 1500px) {
        width: 400px;
        font-size: 16px; }
      @media (max-width: 768px) {
        width: 240px;
        padding: 50px 15px; }

      li {
        margin-bottom: 15px;
        word-break: break-word;
        &.current {
          a span {
            background-color: var(--blue-light);
            color: var(--white);
            border-radius: 5px;
            padding: 5px 10px 5px 10px;
            display: inline-block; } }
        &.has-child {
          a span {
            padding: 5px 30px 5px 10px;
            position: relative;
            &::before {
              @include ba();
              right: 5px;
              top: calc(50% - 4px);
              border-radius: 2px;
              border-top: 10px solid var(--white);
              border-left: 7px solid transparent;
              border-right: 7px solid transparent;
              border-bottom: 10px solid transparent; } } }
        &.active-submenu {
          ul {
            max-height: 300px;
            overflow-y: auto;
            margin: 10px 0 20px 15px;
            @media (max-width: 768px) {
              margin-left: 0px; }
            li {
              margin-bottom: 10px; } } }
        ul {
          max-height: 0px;
          overflow: hidden;
          transition: all .3s ease; }
        a {
          display: flex;
          align-items: center;
          color: var(--gray-dark);
          text-decoration: none;
          &:hover {
            color: var(--blue-light); }
          img {
            display: inline-block;
            margin-right: 15px;
            width: 40px;
            height: 40px;
            object-fit: contain;
            @media (max-width: 1500px) {
              width: 30px;
              height: 30px; }
            @media (max-width: 768px) {
              width: 20px;
              height: 20px; } } } } } } }
