footer {
  padding: 35px 0;
  color: #fff;
  font-size: 16px;
  background-color: var(--gray-dark);
  @media (max-width: 768px) {
    font-size: 14px; }
  img {
    width: 100%; }
  a {
    color: #fff;
    text-decoration: none;
    transition: all .2s ease;
    &:hover {
      opacity: .7; } }
  .logo {
    margin-bottom: 25px;
    @media (max-width: 768px) {
      width: 200px;
      margin: 25px auto 15px; } }
  .working-time {
    color: var(--orange);
    p {
      margin-bottom: 15px; } }
  .tel, .email {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    font-size: 1.2em;
    @media (max-width: 768px) {
      text-align: center;
      justify-content: center; }
    .icon {
      width: 27px;
      height: 27px;
      margin-right: 15px;
      svg {
        fill: var(--orange); } } }
  .payment {
    width: 90%;
    @media (max-width: 768px) {
      width: 200px;
      margin: 15px auto 0; } }

  .container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    & > div {
      width: 17%;
      &:last-child {
        width: 20%; }
      @media (max-width: 1140px) {
        width: 30% !important;
        margin-bottom: 30px;
        &:first-child {
          order: 1; }
        &:last-child {
          order: 2; } }
      @media (max-width: 768px) {
        width: 100% !important;
        text-align: center; }

      ul {
        padding: 0;
        list-style: none;
        li {
          margin-bottom: 15px;
          &:first-child {
            text-transform: uppercase;
            font-weight: 700;
            font-size: 1.1em; } } } } } }
