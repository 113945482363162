*, *::before, *::after {
  margin: 0;
  // padding: 0
  box-sizing: border-box; }

body {
  font-family: 'CenturyGothic', Arial, sans-serif;
  color: var(--gray-light);
  /* Vars */
  --white: #fff;
  --red: rgb(192, 08, 08);
  --orange: rgb(214, 146, 87);
  --gray-dark: rgb(28, 28, 28);
  --gray-light: rgb(33, 33, 33);
  --blue-dark: rgb(0, 102, 153);
  --blue-light: rgb(0, 153, 204);
  font-size: 22px;
  @media (max-width: 1500px) {
    font-size: 20px; }
  @media (max-width: 1300px) {
    font-size: 16px; } }

.container {
  width: 1788px;
  margin: 0 auto;
  // border: 1px solid orange
  @media (max-width: 1840px) {
    width: 1440px; }
  @media (max-width: 1500px) {
    width: 1200px; }
  @media (max-width: 1300px) {
    width: 1100px; }
  @media (max-width: 1140px) {
    width: 95%; }
 }  // @media (max-width: 768px)

h2 {
  color: var(--blue-light);
  font-size: 52px;
  @media (max-width: 1500px) {
    font-size: 44px; }
  @media (max-width: 1300px) {
    font-size: 38px; }
  @media (max-width: 1140px) {
    font-size: 32px; }
  @media (max-width: 768px) {
    font-size: 26px; } }

section {
  padding: 35px 0; }

.blue-line {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 10px;
  background: var(--line-box-bg);
  --box-width: 120px;
  --line-box-bg: var(--blue-dark);
  @media (max-width: 1140px) {
    --box-width: 60px; }
  &::before {
    @include ba();
    right: 10%;
    top: calc(50% - var(--box-width) / 2 + 1px);
    width: var(--box-width);
    height: var(--box-width);
    background-color: #fff;
    transform: rotate(45deg);
    border-right: 10px solid var(--line-box-bg);
    border-bottom: 10px solid var(--line-box-bg); } }
