section.banners {
  .banner-list {
    display: grid;
    grid-template-areas: 'banner-1 banner-1 banner-1 banner-2 banner-2' 'banner-1 banner-1 banner-1 banner-3 banner-3';
    grid-column-gap: 45px;
    grid-row-gap: 30px;
    @media (max-width: 1500px) {
      grid-column-gap: 30px;
      grid-row-gap: 20px; }
    @media (max-width: 768px) {
      grid-template-areas: 'banner-1 banner-1' 'banner-2 banner-3';
      grid-gap: 10px; }

    &-item {
      padding: 30px 35px;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-end;
      border-radius: 5px;
      background-color: #ccc;
      color: #000;
      font-size: 45px;
      text-decoration: none;
      position: relative;
      overflow: hidden;
      &:hover {
        img {
          transform: scale(1.1); } }
      @media (max-width: 1840px) {
        font-size: 35px; }
      @media (max-width: 1500px) {
        font-size: 30px;
        padding: 20px 25px; }
      @media (max-width: 1140px) {
        font-size: 26px; }
      @media (max-width: 768px) {
        font-size: 22px;
        height: 250px !important;
        padding: 10px;
        background-position: 75% center !important; }

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        transition: all .2s ease;
        position: absolute;
        left: 0;
        top: 0;
        z-index: 1; }

      span {
        z-index: 2; }

      &.banner-1 {
        height: 500px;
        grid-area: banner-1;
        // background: center / cover no-repeat url('../img/banner-1.jpg')
        .tel {
          font-size: 1.4em;
          margin-bottom: 15px; } }
      &.banner-2 {
        grid-area: banner-2;
 }        // background: center / cover no-repeat url('../img/banner-2.jpg')
      &.banner-3 {
        grid-area: banner-3;
 } } } }        // background: center / cover no-repeat url('../img/banner-3.jpg')
