section.print-logo {
  overflow: hidden;
  position: relative;
  height: 650px;
  @media (max-width: 1500px) {
    height: 450px; }
  @media (max-width: 1140px) {
    height: 350px; }
  @media (max-width: 768px) {
    height: 300px; }
  .container {
    height: 100%; }

  h2 {
    @media (max-width: 1140px) {
      margin-top: 30px; } }

  h2 span {
    background-color: var(--red);
    color: #fff;
    padding: 0 7px; }

  .wrapper {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    @media (max-width: 768px) {
      height: auto;
      margin-top: 35px; }
    img {
      position: absolute;
      left: 0;
      top: 100px;
      height: 90%;
      @media (max-width: 768px) {
        display: none; } }
    ul {
      list-style: none;
      padding: 0;
      li {
        margin-bottom: 15px;
        padding-right: 70px;
        font-size: 42px;
        text-align: right;
        position: relative;
        --circle-width: 34px;
        @media (max-width: 1500px) {
          font-size: 34px;
          --circle-width: 28px; }
        @media (max-width: 1300px) {
          font-size: 28px; }
        @media (max-width: 768px) {
          font-size: 18px;
          --circle-width: 18px;
          padding-right: 35px; }
        &::before {
          @include ba();
          right: 0;
          top: calc(50% - (var(--circle-width) / 2));
          height: var(--circle-width);
          width: var(--circle-width);
          background-color: var(--red);
          border-radius: 50%; } } } } }
