section.about {
  position: relative;
  overflow: hidden;

  h2 {
    margin-top: 100px;
    margin-bottom: 50px;
    text-align: right;
    text-transform: uppercase; }

  .about-desc {
    max-height: 320px;
    overflow: hidden;
    position: relative;
    transition: all .3s ease;
    &.active {
      max-height: 850px;
      overflow-y: auto;
      &::before {
        display: none; } }
    @media (max-width: 1140px) {
      max-height: 250px; }
    &::before {
      @include ba();
      bottom: 0;
      width: 100%;
      height: 50px;
      background: linear-gradient(0deg,#fff, transparent); }
    p {
      margin-bottom: 35px;
      line-height: 30px;
      font-size: 26px;
      @media (max-width: 1500px) {
        font-size: 24px; }
      @media (max-width: 1300px) {
        font-size: 18px;
        line-height: 24px; } } }

  .read-more {
    margin-top: 20px;
    min-width: 100px;
    min-height: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: transparent;
    border: none;
    cursor: pointer;
    color: var(--blue-light);
    font-size: 26px;
    @media (max-width: 1300px) {
      font-size: 20px; } } }
