@font-face {
  font-family: CenturyGothic;
  src: url("../fonts/CenturyGothic.woff") format("woff"), url("../fonts/CenturyGothic.woff2") format("woff2");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: CenturyGothic;
  src: url("../fonts/CenturyGothic-Bold.woff") format("woff"), url("../fonts/CenturyGothic-Bold.woff2") format("woff2");
  font-weight: 700;
  font-style: bold;
}

*,
*::before,
*::after {
  margin: 0;
  box-sizing: border-box;
}

body {
  font-family: 'CenturyGothic', Arial, sans-serif;
  color: var(--gray-light);
  /* Vars */
  --white: #fff;
  --red: rgb(192, 08, 08);
  --orange: rgb(214, 146, 87);
  --gray-dark: rgb(28, 28, 28);
  --gray-light: rgb(33, 33, 33);
  --blue-dark: rgb(0, 102, 153);
  --blue-light: rgb(0, 153, 204);
  font-size: 22px;
}

.container {
  width: 1788px;
  margin: 0 auto;
}

h2 {
  color: var(--blue-light);
  font-size: 52px;
}

section {
  padding: 35px 0;
}

.blue-line {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 10px;
  background: var(--line-box-bg);
  --box-width: 120px;
  --line-box-bg: var(--blue-dark);
}

.blue-line::before {
  position: absolute;
  display: block;
  content: '';
  right: 10%;
  top: calc(50% - var(--box-width) / 2 + 1px);
  width: var(--box-width);
  height: var(--box-width);
  background-color: #fff;
  transform: rotate(45deg);
  border-right: 10px solid var(--line-box-bg);
  border-bottom: 10px solid var(--line-box-bg);
}

header {
  padding: 35px 0;
  color: var(--white);
  background: linear-gradient(90deg, var(--blue-light), var(--blue-dark));
}

header .navbar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 26px;
  text-align: center;
}

header .navbar .burger {
  display: flex;
  align-items: center;
  width: 75px;
  height: 75px;
  cursor: pointer;
  --btn-color: #fff;
}

header .navbar .burger .burger-btn {
  width: 100%;
  height: var(--burger-height);
  border-radius: 5px;
  background-color: var(--btn-color);
  position: relative;
  --burger-distance: -20px;
  --burger-height: 10px;
}

header .navbar .burger .burger-btn::before,
header .navbar .burger .burger-btn::after {
  position: absolute;
  display: block;
  content: '';
  width: 100%;
  height: var(--burger-height);
  border-radius: 5px;
  background-color: var(--btn-color);
}

header .navbar .burger .burger-btn::before {
  top: var(--burger-distance);
}

header .navbar .burger .burger-btn::after {
  bottom: var(--burger-distance);
}

header .navbar .logo {
  width: 450px;
}

header .navbar .logo img {
  width: 100%;
}

header .navbar .search {
  width: 700px;
  height: 65px;
  display: flex;
  align-items: center;
  position: relative;
  overflow: hidden;
}

header .navbar .search input {
  padding: 0 25px;
  width: 100%;
  height: 120%;
  border: none;
  outline: none;
  border-radius: 40px;
  background: rgba(255, 255, 255, 0.7);
  color: #fff;
  font-size: 28px;
}

header .navbar .search input::placeholder {
  color: #fff;
}

header .navbar .search button {
  width: 34px;
  height: 34px;
  border: none;
  cursor: pointer;
  background: center/contain no-repeat url("../img/search.png");
  position: absolute;
  top: calc(50% - 17px);
  right: 20px;
}

header .navbar .delivery,
header .navbar .cart {
  display: flex;
  align-items: center;
  flex-direction: column;
  color: var(--white);
  text-decoration: none;
}

header .navbar .delivery img,
header .navbar .cart img {
  margin-bottom: 5px;
}

header .sidebar {
  width: 100%;
  height: 100%;
  overflow-y: auto;
  background: rgba(0, 0, 0, 0.5);
  border-right: 1px solid var(--blue-light);
  transition: all .5s ease;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 10;
  opacity: 0;
  visibility: hidden;
}

header .sidebar.active {
  left: 0;
  opacity: 1;
  visibility: visible;
}

header .sidebar.active ul {
  left: 0;
}

header .sidebar .btn-close-sb {
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  position: absolute;
  right: 35px;
  top: 25px;
  --close-bg: #fff;
}

header .sidebar .btn-close-sb::before,
header .sidebar .btn-close-sb::after {
  position: absolute;
  display: block;
  content: '';
  width: 100%;
  height: 4px;
  border-radius: 3px;
  background-color: var(--close-bg);
}

header .sidebar .btn-close-sb::before {
  transform: rotate(45deg);
}

header .sidebar .btn-close-sb::after {
  transform: rotate(-45deg);
}

header .sidebar > ul {
  left: -100%;
  transition: all .3s ease;
  position: absolute;
  background-color: var(--white);
  padding: 70px 30px 50px;
  list-style: none;
  width: 500px;
  min-height: 100vh;
  font-size: 22px;
}

header .sidebar > ul li {
  margin-bottom: 15px;
  word-break: break-word;
}

header .sidebar > ul li.current a span {
  background-color: var(--blue-light);
  color: var(--white);
  border-radius: 5px;
  padding: 5px 10px 5px 10px;
  display: inline-block;
}

header .sidebar > ul li.has-child a span {
  padding: 5px 30px 5px 10px;
  position: relative;
}

header .sidebar > ul li.has-child a span::before {
  position: absolute;
  display: block;
  content: '';
  right: 5px;
  top: calc(50% - 4px);
  border-radius: 2px;
  border-top: 10px solid var(--white);
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;
  border-bottom: 10px solid transparent;
}

header .sidebar > ul li.active-submenu ul {
  max-height: 300px;
  overflow-y: auto;
  margin: 10px 0 20px 15px;
}

header .sidebar > ul li.active-submenu ul li {
  margin-bottom: 10px;
}

header .sidebar > ul li ul {
  max-height: 0px;
  overflow: hidden;
  transition: all .3s ease;
}

header .sidebar > ul li a {
  display: flex;
  align-items: center;
  color: var(--gray-dark);
  text-decoration: none;
}

header .sidebar > ul li a:hover {
  color: var(--blue-light);
}

header .sidebar > ul li a img {
  display: inline-block;
  margin-right: 15px;
  width: 40px;
  height: 40px;
  object-fit: contain;
}

section.banners .banner-list {
  display: grid;
  grid-template-areas: 'banner-1 banner-1 banner-1 banner-2 banner-2' 'banner-1 banner-1 banner-1 banner-3 banner-3';
  grid-column-gap: 45px;
  grid-row-gap: 30px;
}

section.banners .banner-list-item {
  padding: 30px 35px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-end;
  border-radius: 5px;
  background-color: #ccc;
  color: #000;
  font-size: 45px;
  text-decoration: none;
  position: relative;
  overflow: hidden;
}

section.banners .banner-list-item:hover img {
  transform: scale(1.1);
}

section.banners .banner-list-item img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: all .2s ease;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 1;
}

section.banners .banner-list-item span {
  z-index: 2;
}

section.banners .banner-list-item.banner-1 {
  height: 500px;
  grid-area: banner-1;
}

section.banners .banner-list-item.banner-1 .tel {
  font-size: 1.4em;
  margin-bottom: 15px;
}

section.banners .banner-list-item.banner-2 {
  grid-area: banner-2;
}

section.banners .banner-list-item.banner-3 {
  grid-area: banner-3;
}

section.print-logo {
  overflow: hidden;
  position: relative;
  height: 650px;
}

section.print-logo .container {
  height: 100%;
}

section.print-logo h2 span {
  background-color: var(--red);
  color: #fff;
  padding: 0 7px;
}

section.print-logo .wrapper {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

section.print-logo .wrapper img {
  position: absolute;
  left: 0;
  top: 100px;
  height: 90%;
}

section.print-logo .wrapper ul {
  list-style: none;
  padding: 0;
}

section.print-logo .wrapper ul li {
  margin-bottom: 15px;
  padding-right: 70px;
  font-size: 42px;
  text-align: right;
  position: relative;
  --circle-width: 34px;
}

section.print-logo .wrapper ul li::before {
  position: absolute;
  display: block;
  content: '';
  right: 0;
  top: calc(50% - (var(--circle-width) / 2));
  height: var(--circle-width);
  width: var(--circle-width);
  background-color: var(--red);
  border-radius: 50%;
}

section.popular {
  overflow: hidden;
  position: relative;
}

section.popular .blue-line {
  transform: rotateY(180deg);
  --line-box-bg: var(--blue-light);
}

section.popular h2 {
  margin-left: 350px;
  text-transform: uppercase;
}

section.popular .product-list {
  margin-top: 100px;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(30%, 1fr));
  grid-auto-rows: minmax(150px, 250px);
  grid-gap: 45px;
}

section.popular .product-list-item {
  position: relative;
  overflow: hidden;
  display: flex;
  align-items: flex-end;
  padding: 15px;
  text-decoration: none;
  color: var(--gray-light);
}

section.popular .product-list-item:hover img {
  transform: scale(1.1);
}

section.popular .product-list-item span {
  text-transform: uppercase;
  padding: 5px;
  background-color: var(--white);
  z-index: 5;
}

section.popular .product-list-item img {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: all .2s ease;
}

section.about {
  position: relative;
  overflow: hidden;
}

section.about h2 {
  margin-top: 100px;
  margin-bottom: 50px;
  text-align: right;
  text-transform: uppercase;
}

section.about .about-desc {
  max-height: 320px;
  overflow: hidden;
  position: relative;
  transition: all .3s ease;
}

section.about .about-desc.active {
  max-height: 850px;
  overflow-y: auto;
}

section.about .about-desc.active::before {
  display: none;
}

section.about .about-desc::before {
  position: absolute;
  display: block;
  content: '';
  bottom: 0;
  width: 100%;
  height: 50px;
  background: linear-gradient(0deg, #fff, transparent);
}

section.about .about-desc p {
  margin-bottom: 35px;
  line-height: 30px;
  font-size: 26px;
}

section.about .read-more {
  margin-top: 20px;
  min-width: 100px;
  min-height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: transparent;
  border: none;
  cursor: pointer;
  color: var(--blue-light);
  font-size: 26px;
}

footer {
  padding: 35px 0;
  color: #fff;
  font-size: 16px;
  background-color: var(--gray-dark);
}

footer img {
  width: 100%;
}

footer a {
  color: #fff;
  text-decoration: none;
  transition: all .2s ease;
}

footer a:hover {
  opacity: .7;
}

footer .logo {
  margin-bottom: 25px;
}

footer .working-time {
  color: var(--orange);
}

footer .working-time p {
  margin-bottom: 15px;
}

footer .tel,
footer .email {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-size: 1.2em;
}

footer .tel .icon,
footer .email .icon {
  width: 27px;
  height: 27px;
  margin-right: 15px;
}

footer .tel .icon svg,
footer .email .icon svg {
  fill: var(--orange);
}

footer .payment {
  width: 90%;
}

footer .container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

footer .container > div {
  width: 17%;
}

footer .container > div:last-child {
  width: 20%;
}

footer .container > div ul {
  padding: 0;
  list-style: none;
}

footer .container > div ul li {
  margin-bottom: 15px;
}

footer .container > div ul li:first-child {
  text-transform: uppercase;
  font-weight: 700;
  font-size: 1.1em;
}

@media (max-width: 1840px) {
  .container {
    width: 1440px;
  }

  header .navbar {
    font-size: 20px;
  }

  header .navbar .burger {
    width: 55px;
    height: 55px;
  }

  header .navbar .burger .burger-btn {
    --burger-height: 7px;
    --burger-distance: 14px;
  }

  header .navbar .logo {
    width: 350px;
  }

  header .navbar .search {
    width: 500px;
    height: 55px;
  }

  header .navbar .search input {
    font-size: 22px;
  }

  section.banners .banner-list-item {
    font-size: 35px;
  }
}

@media (max-width: 1500px) {
  body {
    font-size: 20px;
  }

  .container {
    width: 1200px;
  }

  h2 {
    font-size: 44px;
  }

  header .navbar {
    justify-content: flex-start;
  }

  header .navbar .burger {
    margin-right: 35px;
  }

  header .navbar .logo {
    width: 250px;
  }

  header .navbar .search {
    margin-left: auto;
    width: 350px;
    height: 40px;
  }

  header .navbar .search input {
    font-size: 18px;
  }

  header .navbar .search button {
    width: 24px;
    height: 24px;
    top: calc(50% - 12px);
  }

  header .navbar .delivery,
  header .navbar .cart {
    margin-left: 25px;
  }

  header .navbar .delivery img,
  header .navbar .cart img {
    width: 34px;
    height: 34px;
    object-fit: contain;
  }

  header .sidebar > ul {
    width: 400px;
    font-size: 16px;
  }

  header .sidebar > ul li a img {
    width: 30px;
    height: 30px;
  }

  section.banners .banner-list {
    grid-column-gap: 30px;
    grid-row-gap: 20px;
  }

  section.banners .banner-list-item {
    font-size: 30px;
    padding: 20px 25px;
  }

  section.print-logo {
    height: 450px;
  }

  section.print-logo .wrapper ul li {
    font-size: 34px;
    --circle-width: 28px;
  }

  section.about .about-desc p {
    font-size: 24px;
  }
}

@media (max-width: 1300px) {
  body {
    font-size: 16px;
  }

  .container {
    width: 1100px;
  }

  h2 {
    font-size: 38px;
  }

  header .navbar {
    font-size: 18px;
  }

  header .navbar .burger {
    width: 45px;
    height: 45px;
  }

  section.print-logo .wrapper ul li {
    font-size: 28px;
  }

  section.popular h2 {
    margin-left: 250px;
  }

  section.popular .product-list {
    grid-gap: 30px;
    grid-auto-rows: minmax(150px, 200px);
  }

  section.about .about-desc p {
    font-size: 18px;
    line-height: 24px;
  }

  section.about .read-more {
    font-size: 20px;
  }
}

@media (max-width: 1140px) {
  .container {
    width: 95%;
  }

  h2 {
    font-size: 32px;
  }

  .blue-line {
    --box-width: 60px;
  }

  header {
    padding: 20px 0;
  }

  header .navbar .burger .burger-btn {
    --burger-height: 5px;
    --burger-distance: 12px;
  }

  header .navbar .logo {
    width: 180px;
  }

  header .navbar .search {
    width: 50px;
    overflow: visible;
  }

  header .navbar .search input,
  header .navbar .search #search {
    transition: all .3s ease;
    opacity: 0;
    visibility: hidden;
  }

  header .navbar .search input {
    width: 200px;
    position: absolute;
    top: 50px;
    right: 0;
    z-index: 4;
    color: #333;
  }

  header .navbar .search input::placeholder {
    color: #555;
  }

  header .navbar .search #search {
    top: 60px;
    z-index: 5;
    transition: all .3s ease;
  }

  header .navbar .search:hover input,
  header .navbar .search:hover #search {
    top: 0;
    opacity: 1;
    visibility: visible;
  }

  header .navbar .search:hover #search {
    top: 9px;
  }

  section.banners .banner-list-item {
    font-size: 26px;
  }

  section.print-logo {
    height: 350px;
  }

  section.print-logo h2 {
    margin-top: 30px;
  }

  section.popular h2 {
    margin-left: 170px;
  }

  section.about .about-desc {
    max-height: 250px;
  }

  footer .container > div {
    width: 30% !important;
    margin-bottom: 30px;
  }

  footer .container > div:first-child {
    order: 1;
  }

  footer .container > div:last-child {
    order: 2;
  }
}

@media (max-width: 768px) {
  h2 {
    font-size: 26px;
  }

  header {
    padding: 15px 0;
  }

  header .navbar {
    justify-content: space-between;
  }

  header .navbar .burger {
    margin-right: 0;
    width: 35px;
    height: 35px;
  }

  header .navbar .burger .burger-btn {
    --burger-height: 4px;
    --burger-distance: 10px;
  }

  header .navbar .search {
    margin: 0;
  }

  header .navbar .delivery,
  header .navbar .cart {
    display: none;
  }

  header .sidebar .btn-close-sb {
    right: 15px;
    top: 15px;
    width: 35px;
    height: 35px;
  }

  header .sidebar > ul {
    width: 240px;
    padding: 50px 15px;
  }

  header .sidebar > ul li.active-submenu ul {
    margin-left: 0px;
  }

  header .sidebar > ul li a img {
    width: 20px;
    height: 20px;
  }

  section.banners .banner-list {
    grid-template-areas: 'banner-1 banner-1' 'banner-2 banner-3';
    grid-gap: 10px;
  }

  section.banners .banner-list-item {
    font-size: 22px;
    height: 250px !important;
    padding: 10px;
    background-position: 75% center !important;
  }

  section.print-logo {
    height: 300px;
  }

  section.print-logo .wrapper {
    height: auto;
    margin-top: 35px;
  }

  section.print-logo .wrapper img {
    display: none;
  }

  section.print-logo .wrapper ul li {
    font-size: 18px;
    --circle-width: 18px;
    padding-right: 35px;
  }

  section.popular h2 {
    margin-left: 120px;
  }

  section.popular .product-list {
    grid-template-columns: repeat(auto-fill, minmax(40%, 1fr));
    grid-auto-rows: minmax(100px, 100px);
    margin-top: 50px;
    grid-gap: 15px;
  }

  section.popular .product-list-item {
    padding: 5px;
  }

  section.popular .product-list-item span {
    font-size: 12px;
  }

  footer {
    font-size: 14px;
  }

  footer .logo {
    width: 200px;
    margin: 25px auto 15px;
  }

  footer .tel,
  footer .email {
    text-align: center;
    justify-content: center;
  }

  footer .payment {
    width: 200px;
    margin: 15px auto 0;
  }

  footer .container > div {
    width: 100% !important;
    text-align: center;
  }
}

@media (max-width: 500px) {
  header .navbar .logo {
    width: 140px;
  }

  section.popular h2 {
    margin-left: 15px;
    margin-top: 15px;
  }
}