section.popular {
  overflow: hidden;
  position: relative;
  .blue-line {
    transform: rotateY(180deg);
    --line-box-bg: var(--blue-light); }
  h2 {
    margin-left: 350px;
    text-transform: uppercase;
    @media (max-width: 1300px) {
      margin-left: 250px; }
    @media (max-width: 1140px) {
      margin-left: 170px; }
    @media (max-width: 768px) {
      margin-left: 120px; }
    @media (max-width: 500px) {
      margin-left: 15px;
      margin-top: 15px; } }
  .product-list {
    margin-top: 100px;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(30%, 1fr));
    grid-auto-rows: minmax(150px, 250px);
    grid-gap: 45px;
    @media (max-width: 1300px) {
      grid-gap: 30px;
      grid-auto-rows: minmax(150px, 200px); }
    @media (max-width: 768px) {
      grid-template-columns: repeat(auto-fill, minmax(40%, 1fr));
      grid-auto-rows: minmax(100px, 100px);
      margin-top: 50px;
      grid-gap: 15px; }

    &-item {
      position: relative;
      overflow: hidden;
      display: flex;
      align-items: flex-end;
      padding: 15px;
      text-decoration: none;
      color: var(--gray-light);
      &:hover {
        img {
          transform: scale(1.1); } }
      @media (max-width: 768px) {
        padding: 5px; }
      span {
        text-transform: uppercase;
        padding: 5px;
        background-color: var(--white);
        z-index: 5;
        @media (max-width: 768px) {
          font-size: 12px; } }

      img {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
        transition: all .2s ease; } } } }
